export const environment = {
  env: 'production',
  production: true,
  legacy: {
    baseUrl: '',
  },
  back: {
    baseUrl: `https://pass.production.bodytech.company/api`,
  },
  fariaLimaId: 34,
  sentry: {
    dsn: 'https://5e817bf5ea834ad390009869cee2c08e@sentry.dev.bodytech.company/2',
    enabled: true,
    environment: 'prod',
    release: 'btpass-d4a4260',
    attachStacktrace: true,
  },
};
